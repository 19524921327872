document.addEventListener('DOMContentLoaded', function () {
    const featureVideo = document.querySelectorAll('.feature__video-container, .feature-boxed__media');
    const translationsElement = document.getElementById("feature-block-translations");
    const translations = translationsElement ? JSON.parse(translationsElement.innerText) : {};
    featureVideo.forEach((feature) => {
        const video = document.querySelector('video');       
        let playing = true;        
        
        function togglePlayPause(text: Element | null, playButton: HTMLButtonElement) {
            playing = !playing;
            if (text)
            {
                text.textContent = playing ? translations.pause : translations.start;
            }
            playButton.setAttribute('aria-label', playing ? translations.pauseVideo : translations.startVideo);
            playButton.classList.remove(playing ? 'paused' : 'playing');
            playButton.classList.add(playing ? 'playing' : 'paused');
            playing ? video?.play() : video?.pause();
        }        
        
        const text = feature.querySelector('.ctrl-text');
        if (text)
        {
            text.textContent = translations.pause
        }
        const playButton = feature.querySelector('.video-controls__play-button');
        playButton?.addEventListener('click', () => togglePlayPause(text, playButton as HTMLButtonElement));
    });
});